import { useState, useEffect } from "react";

const useTotalPrice = (formData) => {
  const [totalPrice, setTotalPrice] = useState({ eur: 49, usd: 49 });

  useEffect(() => {
    let priceEur = 49; // Base price in EUR
    let priceUsd = 49; // Base price in USD
    if (formData.personalizedUsb) {
      priceEur += 22;
      priceUsd += 22;
    }
    if (formData.personalizedVinyl) {
      priceEur += 99;
      priceUsd += 99;
    }
    setTotalPrice({ eur: priceEur, usd: priceUsd });
  }, [formData]);

  return totalPrice;
};

export default useTotalPrice;
